module.exports = [{
      plugin: require('/Users/aldomx/dev/amx/aldo.xyz/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-11917153-9"},
    },{
      plugin: require('/Users/aldomx/dev/amx/aldo.xyz/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/aldomx/dev/amx/aldo.xyz/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/aldomx/dev/amx/aldo.xyz/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
